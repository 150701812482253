<template>
 <div>
  <div class="card-body">
        <div class="form-group">
            <form class="form-group" role="form" action="php/contacto.php" method="POST" id="contacto" title="Nombre">
                             <div class="form-group">
                            <input class="form-control" name="nombre" type="text" id="nombre"  title="Nombre"  placeholder="Nombre">
                             </div>
                            <div class="form-group">
                            <input class="form-control" type="email" required id="email"   title="Email" placeholder="Email">
                            </div>
                            <div class="form-group">
                <input class="form-control name=" name="telefono" type="text" id="telefono"   title="Telefono">
              </div>
              <div class="form-group">
                <textarea class="form-control"  name="mensaje"  id="mensaje"   placeholder="Deja tu mensaje"></textarea>
                <br>
                </div>
                 <div class="form-group">
                   <input type="checkbox" name="recibirboletin" id="recibirboletin" /> <strong>Aceptar politicas privacidad </strong>
                <br>
                <a href="https://www.cmlabtec.com/tratamiento-de-datos.html" title="mantenemos tu informacion  personal confidencial
            " rel="noopener">Politicas Privacidad</a>
           </div>
             <div class="boton-contacto ">
    <input class="btn btn-primary" type="submit" name="enviar"  value="Enviar">
                <input type="hidden" name="estado" value="1">
                </div>
                </form>
        </div>
    </div>
 </div>
</template>
<script>
export default {
  name: 'Formulario',
  props: {
    msg: String
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
