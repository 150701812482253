<template>
  <div class="home">
  <h1>Dejanos Tus Datos</h1>
  <Formulario />
  </div>
</template>
<script>
// @ is an alias to /src
import Formulario from '../components/Formulario.vue'
export default {
  name: 'Contacts',
  components: {
    Formulario
  }
}
</script>
